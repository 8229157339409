<template>
  <div>
    <div class="text-center" v-if="!dataLoaded">
      <v-progress-circular class="mt-6" :size="50" color="primary" indeterminate />
    </div>

    <div class="mx-4" v-else>
      <PageTitle title="Страница кафедры" />

      <v-row class="my-0">
        <v-col cols="12" class="pt-0">
          <v-card class="base-card pa-5 department-content">
            <v-alert type="info" outlined class="body-2" v-if="!dept">
              Для начала работы выберите кафедру из списка
            </v-alert>

            <v-autocomplete
              v-if="showDepartmentsList"
              v-model="dept"
              label="Кафедра"
              placeholder="Выберите кафедру из списка"
              class="departments-list"
              hide-no-data
              hide-details
              return-object
              item-text="name"
              :items="departmentsList"
              @change="setDepartment"
            />

            <div v-if="dept" class="department-info accent--text mt-2">
              <div class="d-flex justify-space-between" v-if="!showDepartmentsList">
                <h2>{{ dept.name }}</h2>

                <v-btn icon color="accent" class="edit-btn" title="Изменить кафедру" @click="showDepartmentsList = true">
                  <v-icon center size="22" class="mr-0">mdi-pencil-outline</v-icon>
                </v-btn>
              </div>

              <p v-if="dept.managerName"><strong>Заведующий кафедрой: </strong>{{ dept.managerName }}</p>
              <p v-if="dept.axPhone"><strong>Телефон: </strong>{{ dept.axPhone }}</p>
              <p v-if="dept.axAddress"><strong>Адрес: </strong>{{ dept.axAddress }}</p>
              <p v-if="dept.axAddrRoute"><strong>Как добраться: </strong>{{ dept.axAddrRoute }}</p>

              <div class="mt-7 department-buttons" v-if="showDepartmentBtnLinks">
                <v-btn small outlined color="primary" class="white--text px-4" to="/department/event-places">
                  <v-icon left size="20">mdi-home-outline</v-icon>Площадки / аудитории
                </v-btn>

                <v-btn small outlined color="primary" class="white--text px-4" to="/department/schedule-templates">
                  <v-icon left size="20">mdi-clipboard-text-outline</v-icon>Шаблоны расписаний
                </v-btn>

                <v-btn small outlined color="primary" class="white--text px-4" to="/department/chapters">
                  <v-icon left size="20">mdi-book-clock-outline</v-icon>Разделы расписания
                </v-btn>

                <v-btn small outlined color="primary" class="white--text px-4" to="/department/time-management">
                  <v-icon left size="20">mdi-clock-outline</v-icon>Время занятий
                </v-btn>

                <v-btn small outlined color="primary" class="white--text px-4" to="/department/reports">
                  <v-icon left size="20">mdi-chart-box-outline</v-icon>Отчеты
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { departmentApi } from '@/api'
import { mapGetters } from 'vuex'
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'
import { setDepartmentToLocalStorage } from '@/scripts'
import PageTitle from '@/components/ui/Title'

export default {
  name: 'Department',

  metaInfo: {
    title: 'Страница кафедры'
  },

  components: {
    PageTitle,
  },

  async created() {
    await this.$store.dispatch('user/getCurrent')
    await this.getDepartmentsList()
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    this.showDepartmentsList = !this.department
    this.dataLoaded = true
  },

  data: () => ({
    departmentsList: [],
    dept: null,
    showDepartmentsList: true,
    dataLoaded: false
  }),

  computed: {
    ...mapGetters('user', ['userId', 'allUserRoles']),
    ...mapGetters('department', ['department']),

    showDepartmentBtnLinks() {
      return [
        'ROLE_SUPERADMIN',
        'ROLE_ADMIN',
        'ROLE_AUDITOR',
        'ROLE_CURATOR',
        'ROLE_DEPARTMENT_HEAD',
        'ROLE_HEAD_TEACHER',
        'ROLE_TRAINING_MANAGER'
      ].some(role => this.allUserRoles.includes(role))
    }
  },

  methods: {
    async getDepartmentsList() {
      try {
        this.departmentsList = await departmentApi.getDepartmentsList()
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка получения списка кафедр')
      }
    },

    setDepartment() {
      const body = {
        userId: this.userId,
        departmentId: this.dept.id
      }

    setDepartmentToLocalStorage(body)
      this.$store.dispatch('department/setDepartment', this.dept)
      this.showDepartmentsList = false
    }
  },

  watch: {
    department: {
      immediate: true,
      handler(val) {
        if (!_isEqual(val, this.dept)) {
          this.dept = _cloneDeep(val)
        }
      }
    }
  }
}
</script>

<style scoped>
.department-content {
  min-height: 160px;
}

.department-info h2 {
  font-weight: 500;
  line-height: 1.1 !important;
  font-size: 22px !important;
  margin-bottom: 24px;
  text-align: left;
}

.department-info p {
  margin-bottom: 4px;
  font-size: 16px;
}

.departments-list {
  padding-top: 8px;
  padding-bottom: 4px;
}

.edit-btn {
  margin-top: -5px;
  margin-right: -4px;
}

.department-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
</style>